/* global document */
import React, { Component } from "react";
import { render } from "react-dom";
import MapGL from "react-map-gl";
import DeckGLOverlay from './overlay.js';
import SettingsPanel from "./settings_panel.js";
import "./styles.css";
import "./icon-atlas.png";

// Set your mapbox token here
const MAPBOX_TOKEN = "pk.eyJ1IjoibG9nbWFvciIsImEiOiJEdXFveFVRIn0.2iWMsOCPOwaMakue1m510Q"; // eslint-disable-line
// const DATA_URL = "data/";
const DATA_URL = "https://storage.googleapis.com/boqa/";

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        ...DeckGLOverlay.defaultViewport,
        width: window.innerWidth - 16,
        height: window.innerHeight - 16
      },
      paths: [],
      incidents: [],
      mergedPath: [],
      accidents: [],
      risk: [],
      x: null,
      y: null,
      tooltipContent: "",
      layerOptions: {
        incidents: false,
        accidents: false,
        paths: true,
        pathsHex: false,
        incidentsHex: false,
        accidentsHex: false,
        "risk 1023-025": true,
        "risk 1023-017": true,
        "turning 1023-025": false,
        "turning 1023-017": false
      }
    };

    fetch(DATA_URL + "all_path_split.json")
      .then(resp => resp.json())
      .then(data => this.setState({ paths: data, mergedPath: data.reduce((mp, p) => mp.concat(p.path), []) }));

    fetch(DATA_URL + "incidents_df_loc.json")
      .then(resp => resp.json())
      .then(data => this.setState({ incidents: Object.values(data) }));
    fetch(DATA_URL + "accidents_df_loc.json")
      .then(resp => resp.json())
      .then(data => this.setState({ accidents: Object.values(data) }));

    fetch(DATA_URL + "risk_cpa_deck.json")
      .then(resp => resp.json())
      .then(data => this.setState({ risk: data }));
  }

  
  _onViewportChange(viewport) {
    this.setState({
      viewport: {...this.state.viewport, ...viewport}
    });
  }

  _setIncidentTooltip({x, y, object}) {
    let tooltipContent = "";
    if (object) {
      tooltipContent = (
        <div>
          <div>{object["Event Date"]} {object["Event Time"]}</div>
          <div>{object.oiva} ({object.vessel_id})</div> 
          <div>{object.Location}</div> 
          <div>{object.Headline}</div> 
        </div>
      ) || <div></div>;
    }
    this.setState({x, y, tooltipContent});
  }

  _setIncidentHexTooltip({x, y, object}) {
    let tooltipContent = "";
    if (object) {
      const tooltipRows = object.points.map(point => {
          return <div>{point["Event Date"]} {point["Event Time"]} - {point.oiva} ({point.vessel_id}) - {point.Location}</div> 
      });
      tooltipContent = <div>{tooltipRows}</div>
    }
    this.setState({x, y, tooltipContent});
  }

  _setPathTooltip({x, y, object}) {
    let tooltipContent = "";
    if (object) {
      tooltipContent = (
        <div>
          <div>{object.vessel_id}</div> 
        </div>
      ) || <div></div>;
    }
    this.setState({x, y, tooltipContent});
  }

  _setRiskTooltip(context) {
    const {x, y, object} = context;
    let tooltipContent = "";
    if (object) {
      tooltipContent = (
        <div>
          <div>{context.layer.id}: {object[2].toFixed(3)}</div> 
        </div>
      ) || <div></div>;
    }
    this.setState({x, y, tooltipContent});
  }

  _setTurningTooltip(context) {
    const {x, y, object} = context;
    let tooltipContent = "";
    if (object) {
      tooltipContent = (
        <div>
          <div>{context.layer.id}: {(object[3]*2).toFixed(3)} deg/min</div> 
        </div>
      ) || <div></div>;
    }
    this.setState({x, y, tooltipContent});
  }

  _renderTooltip() {
    const {x, y, tooltipContent} = this.state;
    return  (
      <div className="tooltip" style={{left: x, top: y, visibility: tooltipContent === "" ? "hidden" : "visible"}}>
        {tooltipContent}
      </div>
    );
  }

  _onSettingChange(name, value) {
    this.setState({layerOptions: {
      ...this.state.layerOptions, [name]: value
    }})
  };

  render() {
    const { viewport, width, height, paths, incidents, risk, mergedPath, layerOptions, accidents } = this.state;

    return (
      <MapGL
        {...viewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/logmaor/cjhq6f7z2dmtw2sl6fk0xkc1o"
        onViewportChange={this._onViewportChange.bind(this)}
      >
        <DeckGLOverlay
          viewport={viewport}
          paths={paths}
          incidents={incidents}
          accidents={accidents}
          mergedPath={mergedPath}
          risk={risk}
          setIncidentTooltip={this._setIncidentTooltip.bind(this)}
          setIncidentHexTooltip={this._setIncidentHexTooltip.bind(this)}
          setPathTooltip={this._setPathTooltip.bind(this)}
          setRiskTooltip={this._setRiskTooltip.bind(this)}
          setTurningTooltip={this._setTurningTooltip.bind(this)}
          layerOptions={layerOptions}
          debug
        />
        { this._renderTooltip() }
        <SettingsPanel layerOptions={layerOptions} onChange={this._onSettingChange.bind(this)}/>
      </MapGL>
    );
  }
}

render(<Root />, document.body.appendChild(document.createElement("div")));
