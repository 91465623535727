import React, { Component } from "react";
import { render } from "react-dom";
import {setParameters} from 'luma.gl';
import DeckGL, {
  LineLayer,
  ScatterplotLayer,
  MapController,
  PathLayer,
  HexagonLayer,
  IconLayer
} from "deck.gl";

export default class DeckGLOverlay extends Component {
  static get defaultViewport() {
    return { latitude: 18, longitude: -64, zoom: 6, bearing: 0, pitch: 60 };
  }

  _initialize(gl) {
    setParameters(gl, {
      // blendFunc: [gl.SRC_ALPHA, gl.ONE, gl.ONE_MINUS_DST_ALPHA, gl.ONE],
      // blendEquation: gl.FUNC_ADD
    });
  }

  render() {
    const { viewport, paths, mergedPath, incidents, risk, setIncidentTooltip, setPathTooltip, layerOptions, setIncidentHexTooltip, setRiskTooltip, setTurningTooltip, accidents } = this.props;

    const pathsHexLayer = new HexagonLayer({
      id: "pathsHex",
      data: mergedPath,
      // pickable: true,
      extruded: true,
      radius: 10000,
      elevationScale: 500,
      elevationLowerPercentile: 98,
      colorRange: [[240,249,232,100],[204,235,197,100],[168,221,181,100],[123,204,196,100],[67,162,202,100],[8,104,172,100]],
      // getColor: d => colorToRGBArray(d.color),
      getPosition: d => d,
      visible: layerOptions["pathsHex"]
      // onHover: ({object}) => setTooltip(object.name)
    });

    const pathsLayer = new PathLayer({
      id: "paths",
      data: paths,
      widthScale: 20,
      widthMinPixels: 5,
      getPath: d => d.path,
      // getColor: d => colorToRGBArray(d.color),
      getColor: d => (d.vessel_id === "1023-025" || d.vessel_id === "1023-017") ? [d.color[0], d.color[1], d.color[2], 200] : [d.color[0], d.color[1], d.color[2], 5],
      getWidth: d => 10,
      pickable: true,
      onHover: setPathTooltip,
      visible: layerOptions["paths"]
    });

    const incidentsLayer = new IconLayer({
      id: "incidents",
      data: incidents,
      pickable: true,
      iconAtlas: 'icon-atlas.png',
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 128,
          height: 128,
          anchorY: 128,
          mask: true
        }
      },
      sizeScale: 20,
      getPosition: d => [d.longitude, d.latitude],
      getIcon: d => 'marker',
      getSize: d => 3,
      getColor: d => d.color,
      onHover: setIncidentTooltip,
      visible: layerOptions["incidents"]
    });

    const accidentsLayer = new IconLayer({
      id: "accidents",
      data: accidents,
      pickable: true,
      iconAtlas: 'icon-atlas.png',
      iconMapping: {
        marker: {
          x: 128,
          y: 0,
          width: 128,
          height: 128,
          anchorY: 128,
          mask: true
        }
      },
      sizeScale: 20,
      getPosition: d => [d.longitude, d.latitude],
      getIcon: d => 'marker',
      getSize: d => 5,
      getColor: d => d.color,
      onHover: setIncidentTooltip,
      visible: layerOptions["accidents"]
    });
  

    const incidentsHexLayer = new HexagonLayer({
      id: "incidentsHex",
      data: incidents,
      pickable: true,
      getPosition: d => [d.longitude, d.latitude],
      extruded: true,
      radius: 10000,
      elevationScale: 500,
      colorRange: [[255,255,178,100],[254,217,118,100],[254,178,76,100],[253,141,60,100],[240,59,32,100],[189,0,38,100]],
      // getColor: d => colorToRGBArray(d.color),
      onHover: setIncidentHexTooltip,
      visible: layerOptions["incidentsHex"]
    });

    const accidentsHexLayer = new HexagonLayer({
      id: "accidentsHex",
      data: accidents,
      pickable: true,
      getPosition: d => [d.longitude, d.latitude],
      extruded: true,
      radius: 10000,
      elevationScale: 500,
      // colorRange: [[255,255,178,100],[254,217,118,100],[254,178,76,100],[253,141,60,100],[240,59,32,100],[189,0,38,100]],
      colorRange: [[254,235,226,100],[252,197,192,100],[250,159,181,100],[247,104,161,100],[197,27,138,100],[122,1,119,100]],
      // getColor: d => colorToRGBArray(d.color),
      onHover: setIncidentHexTooltip,
      visible: layerOptions["accidentsHex"]
    });

    const riskLayer025 = new ScatterplotLayer({
      id: "risk 1023-025",
      data: (risk["1023-025"] || {risk:[]}).risk,
      radiusScale: 1000,
      getPosition: d => [d[0], d[1]],
      getColor: d => [254,178,76],
      getRadius: d => Math.sqrt(d[2]),
      pickable: true,
      onHover: setRiskTooltip,
      visible: layerOptions["risk 1023-025"],
      fp64: true
    });

    const riskLayer017 = new ScatterplotLayer({
      id: "risk 1023-017",
      data: (risk["1023-017"] || {risk:[]}).risk,
      radiusScale: 1000,
      getPosition: d => [d[0], d[1]],
      getColor: d => [178, 254, 76],
      getRadius: d => Math.sqrt(d[2]),
      pickable: true,
      onHover: setRiskTooltip,
      visible: layerOptions["risk 1023-017"],
      fp64: true
    });

    const turningLayer025 = new ScatterplotLayer({
      id: "turning 1023-025",
      data: (risk["1023-025"] || {risk:[]}).risk,
      radiusScale: 1000,
      getPosition: d => [d[0], d[1]],
      getColor: d => d[3]*2 > 15 ? [30,254,20] : [30,254,20, 0],
      getRadius: d => Math.sqrt(d[3]*2),
      onHover: setTurningTooltip,
      pickable: true,
      visible: layerOptions["turning 1023-025"]
    });

    const turningLayer017 = new ScatterplotLayer({
      id: "turning 1023-017",
      data: (risk["1023-017"] || {risk:[]}).risk,
      radiusScale: 1000,
      getPosition: d => [d[0], d[1]],
      getColor: d => d[3]*2 > 15 ? [254,30,20] : [254,30,20, 0],
      getRadius: d => Math.sqrt(d[3]*2),
      onHover: setTurningTooltip,
      pickable: true,
      visible: layerOptions["turning 1023-017"]
    });

    const layers = [pathsHexLayer, incidentsLayer, pathsLayer, incidentsHexLayer, riskLayer025, riskLayer017, turningLayer017, turningLayer025, accidentsLayer, accidentsHexLayer];

    return (
      
        <DeckGL
          {...viewport}
          layers={layers}
          onWebGLInitialized={this._initialize}
        />
    );
  }
}
