import React, { Component } from "react";

export default class SettingsPanel extends Component {
  render() {
    const { layerOptions, onSettingChange, onChange } = this.props;
    const controls = Object.entries(layerOptions).map(([k, v]) => {
      return <GenericInput name={k} value={v} key={k} onChange={onChange}/>
    });

    return <div className="settings">{controls}</div>;
  }
}

class GenericInput extends Component {

  _onChange(evt) {
    const {value, type} = evt.target;
    let newValue = value;
    if (type === 'checkbox') {
      newValue = evt.target.checked;
    }

    if (type === 'number') {
      newValue = Number(value);
    }
    return this.props.onChange(this.props.name, newValue);
  };

  render() {
    const {name, value} = this.props;
    if (typeof value === "boolean") {
      return (
        <div className="input" key={name}>
          <label>{name}</label>
          <input type="checkbox" checked={value} onChange={this._onChange.bind(this)} />
        </div>
      );
    }
  }
}
